/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import React, { FC, useEffect, useMemo } from 'react'
import { Divider, Icon, Anchor } from 'antd'
import ReactMarkdown from 'react-markdown'
import Wrapper from './Wrapper'
import { BoardInner } from './Board'
import { ViewBar } from './Articles'
import { HeadingBlock, CodeBlock } from './CodeBlock'
import { useSelector, useDispatch } from 'react-redux'
import { actions } from "pages/store"
import produce from "immer"
import { Cloud } from 'parse'

const Detail: FC = (props: any) => {
  const { Link } = Anchor
  let d = useDispatch()
  let articleList = useSelector((s: any) => s.articleList)
  // 能在store缓存找到就用缓存,不然用新建的
  let articleId = props.match.params.id
  let article = articleList.find((it:any) => it.id === articleId) || ({content: '', tags: [], countT: 0, contR: 0, countC: 0, createdAt: Date.now()})
  useEffect(() => {
    document.documentElement.scrollTop = document.body.scrollTop = 0
    if (!article.content) {
      Cloud.run('getArticle', {id: articleId}).then(it => {
        if (articleList.length) { 
          d(actions.articleList(produce(articleList, (next: any) => {
            let index = articleList.findIndex((it:any) => it.id === articleId)
            next[index] = it
          })))
        } else {
          d(actions.articleList([it]))
        }
      })
    }
  }, [])
  const handleClick = (e: any) => {
    e.preventDefault();
  }

  let linkList = useMemo(() => {
    let temp: any = article.content.split('\n')
    let linkList:any = []
    temp.forEach((it:string) => {
      let str = it.trim()
      if (str.startsWith('## ')) {
        linkList.push({value: str.slice(3).split(' ##')[0], sub: [] })
      } else if(str.startsWith('### ')) {
        linkList[linkList.length - 1].sub.push(str.slice(4).split(' ###')[0])
      }
    })
    return linkList
  }, [article.content])

  return (
    <Wrapper className="detail" css={css`display: grid; grid-template-columns: calc(100% - 200px) 200px; @media (max-width: 800px) { display: block;}`}>
      <div>
        <div css={css`padding: 0 20px;`}>
          <div css={css`color:#000b; font-size: 18px; margin: 5px 0`}>{article.title || 'loading now...'}</div>
          <div>
            {article.tags.map((item: React.ReactNode, i:number) => (
              <span key={i} css={css`margin-right: 8px; border:1px solid #999; border-radius: 5px; padding: 0px 3px; color: #999; font-size: 12px;`}>{item}</span>
            ))}
          </div>
        </div>
        <Divider css={css`margin: 15px 0; background: #0002!important`}/>
        <div css={css`padding: 0 20px;`}>
          {article.content ? <ReactMarkdown source={article.content} 
            escapeHtml={true}
            renderers={{
              code: CodeBlock,
              heading: HeadingBlock
            }}
          /> : <div css={css`min-height: 200px;`}>now loading...</div> }
        </div>
        <Divider css={css`margin: 15px 0; background: #0002!important`}/>
        <ViewBar css={css`padding: 0 20px`} it={article} />
        <BoardInner type="commentList" articleId={articleId} />
      </div>
      <div css={css`margin-left: 2vw; @media (max-width: 800px) { display: none;}
        > div {position: sticky; top: 0; margin-top: 20vh}; 
        div[aria-hidden=true] {display: none};
        .ant-affix{position: static !important}`} >

      
      <Anchor affix={true} onClick={handleClick} css={css`background: #0000;`}>
        {linkList.map((it: any) => (
          <Link href={`#${it.value}`} title={`${it.value}`}>
            {it.sub.map((it: string) => (
              <Link href={`#${it}`} title={`${it}`} />
            ))}
          </Link>
        ))}
        <Link href="#comment" title="评论区" />
      </Anchor>
      </div>
    </Wrapper>
  )
}

export default Detail
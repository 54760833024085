/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FC, useEffect } from 'react'
import { Route, Switch, useLocation } from "react-router-dom"
import Header from './page/Header'
import Article from './page/Articles'
import About from './page/About'
import Board from './page/Board'
import Blog from './page/ArticleDetail'
import bg1 from '../assets/bg1.jpg'
import bg2 from '../assets/bg2.jpg'
import bg3 from '../assets/bg3.jpg'

const App: FC = () => {
  useEffect(() => {
    let dom = document.querySelector('#loading')
    dom && dom.remove()
  },[])
  let { pathname } = useLocation()
  let index = pathname === '/about' ? 2 : pathname === '/board' ? 1 : 0
  const config = {
    bgi: [bg1, bg2, bg3],
    bgic: ['#fff', '#2b3935', '#5795da'],
    bgc: ['#eee8', '#76968955', '#eee8'],
    bgcn: ['#fffffff3', '#2f443cee', '#5b97dbee'],
    color: ['#333', '#eee', '#fff']
  }
  const filter = "backdrop-filter: blur(15px)"
  let bgc = CSS.supports(filter) || CSS.supports('-webkit-'+ filter)  ? config.bgc[index]: config.bgcn[index]
  let height = window.innerHeight - 80
  // &:before{content: '';position: fixed; z-index: -1; top: 0; left: 0; right: 0; bottom: 0; 
  return (
      <div css={css`background: url('${config.bgi[index]}')  top/100% repeat-y fixed ${config.bgic[index]};  
        }`}>
        <Header bgc={bgc} color={config.color[index]} />
        <div css={css`padding: 1px 0; min-height: ${height}px`}>
          <Switch>
            <Route exact path='/' component={Article}></Route>
            <Route exact path='/board' component={Board}></Route>
            <Route exact path='/about' component={About}></Route>
            <Route exact path='/blog/:id' component={Blog}></Route>
          </Switch>
        </div>
        <div css={css`box-shadow: 0 -3px 15px 1px #4441;font-size:13px;text-align: center; line-height: 30px; height: 30px; background: ${bgc}; backdrop-filter: blur(15px); color: ${config.color[index]}`}>
          Copyright © 2020 www.hnh.me 版权所有
        </div>
      </div>
  )
}

export default App

import produce from "immer"
// import { combineReducers } from 'redux'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

interface IState {
  needLoadAriticle: boolean, // articleList长度不一定对,因为可能从详情页面刷新已经length == 1 还需要重新刷新
  boardList: []
  commentList: []
  articleList: [],
  Tags: string[],
  choosedTag: string,
  [propName: string]: any
}
interface IAction {
  type: string;
  val: any
}

const defaultState: IState = {
  needLoadAriticle: true,
  boardList: [],
  commentList: [],
  articleList: [],
  Tags: ['全部', '原创', '转载'],
  choosedTag: '全部'
}

const types = Object.keys(defaultState)
export const actions: any = {}
const mutations: any = {}
types.forEach(it => {
  actions[it] = (val: any): IAction => ({type: it, val})
  mutations[it] = (state: IState, action: IAction ) => {
    return produce(state,  (nextState) => {
      nextState[action.type] = action.val
    })
  }
})

const mainReducer = (state = defaultState, action: IAction) => {
  if (mutations[action.type]) {
    return mutations[action.type](state, action)
  }
  return state
}

// export const reducer = combineReducers({
//   mainReducer
// })

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  mainReducer,
  composeEnhancers(applyMiddleware(thunk))
)
export default store
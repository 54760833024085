/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FC, useState, useEffect } from 'react'
import { Card, Input, Button, Divider, Avatar, message } from 'antd'
import { useSelector, useDispatch, useStore } from 'react-redux'
import Wrapper from './Wrapper'
import { actions } from '../store'
import { format } from 'date-fns'
import { Cloud, User } from 'parse'
import produce from "immer"

const Board: FC  = () => {
  return (
    <Wrapper className="page-board"  css={css`max-width: 800px;`}>
      <BoardInner type="boardList"/>
    </Wrapper>
  )
}
export const BoardInner = (props: {type: 'boardList' | 'commentList'; className?: string; articleId?: string}) => {
  interface iList {
    id: string;
    name: string;
    content: string;
    createdAt: number
  }
  const configAll = {
    boardList: {
      title: '留言板',
      msg: '留言',
      headColor: '#ddd',
      color: '#ccc',
      avabgc: '#fff5',
      avaColor: '#eee',
      nameColor: '#bbb'
    },
    commentList: {
      title: '评论内容',
      headColor: '#000c',
      msg: '评论',
      color: '#0009',
      avabgc: '#5553',
      avaColor: '#fff',
      nameColor: '#0007'
    }
  }
  const config = configAll[props.type]

  let d = useDispatch()
  let list = useSelector((state: any) => state[props.type])
  let [loadingMessage, setLoadingMessage] = useState('now loading...')
  let [ani, setAnimation] = useState('')
  useEffect (() => {
    if (!list.length || props.articleId) {
        Cloud.run('getComment', { articleId: props.articleId }).then((it: any) => {
          setAnimation('all')
          setLoadingMessage('暂无')
          d(actions[props.type](it))
        })
    } else {
      setLoadingMessage('暂无')
    }
  }, [])
  let wrapper = props.type === 'boardList' ? 
  `display: grid; grid-template-columns: calc(60% - 1vw) calc(40% - 1vw); gap: 2vw;
  @media (max-width: 600px) { display: flex; flex-flow: wrap-reverse` : 'display: flex; flex-flow: wrap-reverse'

  return (
    <div className={props.className} css={css(wrapper)}>
      <Card title={config.title} headStyle={{color:config.headColor}} bordered={false} css={css`color: ${config.color}; width: 100%`}>
        {list.length ? list.map((it: iList, i: number) => (
          <div key={it.id} css={css`${(ani === 'one' && i === 0) || ani ==='all' ? 'animation: show .5s;' : '' }`}>
            <div css={css`display: flex;`}>
              <div css={css`margin-right: 14px;`}>
                <Avatar shape="square" css={css`background: ${config.avabgc}; color: ${config.avaColor}; text-align: center'`} size="large">
                  {it.name.slice(0, 2)}
                </Avatar>
              </div>
              <div>
                <div css={css`font-size:13px; color: ${config.nameColor}`}>
                  {it.name}<span css={css`margin-left: 8px;font-size:12px; color: #ababab`}>{format(it.createdAt, 'yyyy-MM-dd HH:mm')}</span>
                </div>
                <div css={css`white-space: pre-wrap; word-break: break-all`}>{it.content}</div>
              </div>
            </div>
            {i !== list.length - 1 ? <Divider css={css`background: #aaa5`}/> : ''}
          </div>
          
        )) : <div>{loadingMessage}</div>}
      </Card>
      <Add setAnimation={setAnimation} type={props.type} loadingMessage={loadingMessage} config={config} articleId={props.articleId} />
    </div>
  )
}

const Add = (props: any) => {
  let [input, setInput] = useState('')
  let [loading, setLoading] = useState(false)
  let [name, setname] = useState('')

  let user = User.current()
  let localName = user && user.get('name')
  useEffect(() => {
    setname(localName)
  }, [localName])
  let store = useStore().getState()
  let list = store[props.type]
  let articleList = store.articleList 
  let d = useDispatch()
  const config = props.config

  
  async function addComment(name: string, content: string) {
    name = name.trim() 
    let user = User.current() 
    const nameMessage = user && user.get('name') ? '' : checkname(name)
    if(nameMessage)  {
      message.warning(nameMessage)
      setname('')
      return
    }
    if (!input.trim()) {
      message.warning(`请输入${config.msg}内容`)
      return
    }
    setLoading(true)
    let res: any = await Cloud.run('addComment', { name, content, articleId: props.articleId })
    if (res.token) {
      await User.become(res.token)
    }
    props.setAnimation('one')
    user = User.current()
    d(actions[props.type]([{
      id: Date.now(),
      name,
      createdAt: Date.now(),
      content: input
    }, ...list]))
    if (props.articleId) { // 评论数刷新,只考虑非刷新进详情页面内
      let index = articleList.findIndex((it:any) => it.id === props.articleId)
      if (index > -1) {
        d(actions.articleList(produce(articleList, (next: any)=>{
          next[index].countC = articleList[index].countC + 1
        })))
      }
    }
    setInput('')
    message.success(`${config.msg}成功`)
    setLoading(false)
  }

  function checkname ( name: string ) : string {
    if (name === '') {
      return '请输入您的昵称'
    } else if (name.length > 8) {
      return '昵称最大限制8位'
    } else if (!/^[\u4E00-\u9FA5a-zA-Z0-9]{1,8}$/.test(name)) {
      return '昵称限制数字英文和中文'
    } else if (['云生', '桂彬', '博主'].some(it => name.search(it) !== -1)) {
      return '您的昵称不合法请修改'
    } else {
      return ''
    }
  }

  return (
    <Card id="comment" title={`添加${config.msg}`} headStyle={{color: config.headColor}} bordered={false} css={css`width: 100%`}>
      <div css={css`max-width: 250px`}>
        {localName ? <div css={css`color: ${config.color}`}>{localName}您好!</div> : 
        <Input value={name} onChange={e => setname(e.target.value)} placeholder="请输入您的昵称" css={css`background-color: #fff0; border: 1px solid #aaa; color: ${config.color}`}/>}
        <Input.TextArea placeholder={`请输入您的${config.msg}`} value={input} onChange={(e) => setInput(e.target.value)} css={css`background-color: #fff0; border: 1px solid #aaa; color: ${config.color}; margin: 15px 0;`}/>
        <Button disabled={props.loadingMessage === 'now loading...'} loading={loading} css={css`opacity: 0.75`} type="primary" onClick={() => addComment(name, input)}>添加</Button>
      </div>
      
    </Card>
  )
}

export default Board
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FC } from 'react'
import { useLocation } from "react-router-dom"
interface Iprops {
  className?: string
}
const Wrapper: FC<Iprops> = (props) => {
  let { pathname } = useLocation()
  let index = pathname === '/about' ? 2 : pathname === '/board' ? 1 : 0
  const config = {
    bgc: ['#eee8', '#76968955', '#eee8'],
    bgcn: ['#fffffff3', '#2f443cee', '#5b97dbee']
  }
  const filter = 'backdrop-filter: blur(15px)'
  return (
    <div className={props.className} css={css`max-width: 1024px; background:${CSS.supports(filter) || CSS.supports('-webkit-'+ filter) ? config.bgc[index]: config.bgcn[index]}; 
      backdrop-filter: blur(15px); border-radius: 5px;
      width: calc(80% + 60px); margin: 3vw auto; padding: 2vw; animation: show .5s;`}>
        {props.children}
    </div>
  )
}

export default Wrapper
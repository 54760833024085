/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { FC } from 'react'
import { Card, Timeline, Icon, Avatar } from 'antd'
import Wrapper from './Wrapper'

const AboutMe = () => {
  return (
    <Card headStyle={{color: '#fff'}} title="关于我" bordered={false} css={css`margin-right: 2vw; color: #fff`}>
      <div css={css`display: flex`}>
        <div css={css`margin-right: 1.8vw`}>
          <Avatar shape="square" css={css`background: #fff3;`} size="large">
            博主
          </Avatar>
        </div>
        <div>
          <div css={css`font-weight: 700; margin-bottom: 5px`}>桂彬</div>
          <div>18年正式入行前端工程师.做事认真踏实,有专研精神,喜欢唱歌和前端技术.微信GUTS111.</div>
        </div>
      </div>
      <div css={css`font-size: 13px; margin-top: 15px;word-break: break-all`}>
        一直想做一个自己博客,构思很久现在终于制作完成了.作者并不是一个很爱发文章的人,欢迎大家来踩.
        此博客前端React + Redux + Hooks + TS. 后端MongoDB + Node.js + Parse.<br/>
        其他作品: <a href="//bbs.hnh.me">vue论坛demo</a> <a href="//bbs2.hnh.me">React论坛demo</a> 
      </div>
    </Card>
  )
}

const TimeLine = () => {
  return (
    <Card headStyle={{color: '#fff'}} title="时间线" bordered={false} css={css`color: #fff!important`}>
      <Timeline>
        <Timeline.Item css={css`color: #fff`}>测试完毕正式上线 2020-03-03</Timeline.Item>
        <Timeline.Item css={css`color: #fff`}>后端基本功能完成 2020-03-01</Timeline.Item>
        <Timeline.Item css={css`color: #fff`} dot={<Icon type="clock-circle-o"></Icon>}>前端基本功能完成 2020-02-28</Timeline.Item>
      </Timeline>
    </Card>
  )
}



const About: FC = (p) => {
  return (
    <Wrapper className="page-me" css={css`display: grid; grid-template-columns: 60% 40%; max-width: 800px; @media (max-width: 600px) { display: block;`}>
      <AboutMe/>
      <TimeLine/>
    </Wrapper>
  )
}

export default About
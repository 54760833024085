import React from "react";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter"
// 设置高亮样式
import { coy } from "react-syntax-highlighter/dist/esm/styles/prism"
import { Divider } from 'antd'

// import { jsx, javascript, sass, scss } from "react-syntax-highlighter/dist/esm/languages/prism"
// 注册要高亮的语法，
// 注意：如果不设置打包后供第三方使用是不起作用的
// SyntaxHighlighter.registerLanguage("jsx", jsx);
// SyntaxHighlighter.registerLanguage("javascript", javascript);

export const CodeBlock = (props: any) => {

  return (
    <figure className="highlight">
      <SyntaxHighlighter language={props.language} style={coy}>
        {props.value}
      </SyntaxHighlighter>
    </figure>
  )

}



const elements: any = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6"
}


function Heading({ level, children, ...props }: any) {
  return React.createElement(elements[level] || elements.h1, props, children)
}




export const HeadingBlock = (props: any) => {
  const renderHtml = () => {
    const { level, children } = props
    if (children && children.length > 0) {
      const nodeValue = children[0].props.value
      return (
        <>
          <Heading level={`h${level}`} id={nodeValue}>
            {children}
          </Heading>
         { level === 1 || level === 2 ? <Divider/> : '' }
        </>
      );
    } else {
      return <>{children}</>
    }
  };

  return <>{renderHtml()}</>
}
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import React, { FC, useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import { Card, Tag, Divider, Avatar, Icon } from 'antd'
import { useSelector, useDispatch, useStore } from 'react-redux'
import { actions } from '../store'
import Wrapper from './Wrapper'
import { format } from 'date-fns'
import { Cloud, User } from 'parse'
import produce from "immer"

const Acticle: FC = () => {
  let [animation, setAnimation] = useState('')
  let d = useDispatch()
  let articleList = useSelector((s: any) => s.articleList)
  let needLoadAriticle = useSelector((s: any) => s.needLoadAriticle)
  let choosedTag: string = useSelector((s: any) => s.choosedTag)
  useEffect(() => {
    if (needLoadAriticle) {
      setAnimation('animation: show .5s;')
      Cloud.run('getArticles').then((it: any) => {
        d(actions.needLoadAriticle(false))
        d(actions.articleList(it))
        setTimeout(() => {
          setAnimation('animation: none;')
        }, 600)
      })
      
    }
  },[])
  let [list, setList] = useState([])
  useEffect(() => {
    switch (choosedTag) {
      case '全部': setList(articleList);break;
      case '原创': setList(articleList.filter((it:any) => it.author === '博主'));break;
      case '转载': setList(articleList.filter((it:any) => it.author !== '博主'));break;
      default: setList(articleList.filter((it:any) => it.tags.includes(choosedTag)))
    }
  },[choosedTag, articleList])

  return (
    <Wrapper css={css`display: grid; grid-template-columns: 30% 70%; @media (max-width: 800px) { display: block;}`}>
      {/* 类型后续要修改 */}
      <Cate/>
      <Card title="博文" bordered={false}>
        <div>
        {list.length ? list.map((it: any, i: number) => (
          <div css={css`${animation}`} key={it.id}>
            <div css={css`display: flex; @media (max-width: 600px) { flex-wrap: wrap;}`}>
              <div css={css`@media (max-width: 600px) { min-width: 100%;}`}>
                <div css={css`display: flex;`}>
                  <div css={css`margin-right: 1.5vw;`}>
                    <Avatar shape="square" css={css`background: #5553; color: #fff; text-align: center'`} size="large">
                      {it.author.slice(0, 2)}
                    </Avatar>
                  </div>
                  <div>
                    <Link to={`/blog/${it.id}`}>
                      <div css={css`color: #000b;font-size: 15px; font-weight: 400; margin-bottom: 5px;  transition: .2s; &:hover{ color: #1890ff;}`}>{it.title}</div>
                    </Link>
                    <div>
                      {it.tags.map((item: React.ReactNode, i:number) => (
                        <span key={i} css={css`margin-right: 8px; border:1px solid #999; border-radius: 5px; padding: 0px 3px; color: #0009; font-size: 12px;`}>{item}</span>
                      ))}
                    </div>
                  </div>
                </div>
                <Link to={`/blog/${it.id}`}>
                  <div css={css`margin-top: 8px; font-size: 14px; color: #0008`}>{it.describe}</div>
                </Link>
              </div>
              {it.picUrl ? <div css={css`flex: 0 0.1 auto; margin-left: 2vw;width: 220px; display:flex; align-items:center; @media (max-width: 600px) {margin: 10px 0 }`}><img css={css`width: 100%`} alt="pic" src={it.picUrl}/></div> : ''}
            </div>
            <ViewBar it={it} />

            
            {i !== list.length - 1 ? <Divider/> : ''}
          </div>
        )) : <div css={css`color: #aaa; font-size: 16px; min-height: 30vh`}>now loading...</div>}
        </div>
      </Card>
      
    </Wrapper>
  )
}
export default Acticle

export const ViewBar = (props: any) => {
  const constlist = [{
    prop: 'author',
    icon: 'user'
  },{
    prop: 'countR',
    icon: 'eye'
  } , {
    prop: 'countC',
    icon: 'message'
  }, {
    prop: 'countT',
    icon: 'like'
  } , {
    prop: 'createdAt',
    icon: 'clock-circle'
  }]
  let loading = false
  let d = useDispatch()
  let articleList = useStore().getState().articleList
  function handleClick (prop:string, it: any) {
    if (prop !== 'countT' || loading) {
      return
    } 
    loading = true
    Cloud.run('setThumb', {articleId: it.id}).then((res) => {
      if (res.token) {
        User.become(res.token)
      }
      let index = articleList.findIndex((item:any) => item.id === it.id)
      if (index > -1) {
        d(actions.articleList(produce(articleList, (next: any) => {
          next[index].thumb = !it.thumb
          next[index].countT = it.thumb ? it.countT - 1 : it.countT + 1 
        })))
      }
      loading = false
    })    
  }
  return (
    <ul className={props.className} css={css`margin-top: 5px; color: #0009`}>
    {constlist.map((item, i) => (
      <li key={item.prop} css={css`position: relative; padding: 0 8px; font-size: 13px;@media(max-width: 450px){font-size: 12px}; &:first-of-type{padding-left: 0;}`}>
        <span onClick={() => handleClick(item.prop, props.it)} css={css`${item.prop === 'countT' ?'cursor: pointer; &:hover i { color: #1890ff }' : ''}`}>
          <Icon theme={(item.prop === 'countT' && props.it.thumb) ? 'filled' : 'outlined'} type={item.icon} css={css`margin-right: 4px;${item.prop === 'countT' && props.it.thumb ? 'color:#1890ff;':''}`}/>
            {item.prop !== 'createdAt' ? props.it[item.prop] : format(props.it.createdAt, 'yyyy-MM-dd')}
        </span>
        {i !== constlist.length - 1 ? <em css={css`position: absolute; top: 50%; right: 0; width: 1px; height: 14px; margin-top: -7px; background-color: #aaa;`}></em>: ''}
      </li>
    ))}
    
    </ul>
  )
}

const Cate = () => {
  const { CheckableTag } = Tag
  let d = useDispatch()
  let Tags: string[] = useSelector((s: any) => s.Tags)
  let choosedTag: string = useSelector((s: any) => s.choosedTag)
  useEffect(() => {
    if (Tags.length === 3) {
      Cloud.run('getTags').then((it: any) => {
        d(actions.Tags([...Tags, ...it]))
      })
    }
  },[])
  function handleChange (checked: boolean, it: string) {
    if (it === '全部' || (it !== '全部' && checked === false)) {
      d(actions.choosedTag('全部'))
    } else {
      d(actions.choosedTag(it))
    }

  }
  return (
    <Card title="分类" bordered={false} css={css`margin-right: 2vw;.ant-card-body { padding: 24px 8px 24px 24px}; @media (max-width: 800px) {margin-right: 0 }`}>
      {Tags.map((it,i) => (
        <CheckableTag css={css`cursor: pointer;`} onChange={(checked: boolean) => handleChange(checked, it)}  checked={it === choosedTag} key={i}>{it}</CheckableTag>
      ))}
    </Card>
  )
}
/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Link } from 'react-router-dom'
import { useLocation } from "react-router-dom"

const Header = (props: any) => {
  let l = useLocation()
  let arr = [{
    name: '博客首页',
    to: '/'
  }, {
    name: '留言板',
    to: '/board'
  }, {
    name: '关于我',
    to: '/about'
  }]
  return (
    <header  css={css`display:flex; width:100%; height: 50px; line-height: 50px; box-shadow: 0 3px 15px 1px #4441;
      color: ${props.color}; backdrop-filter: blur(15px); background: ${props.bgc}; transition: background .5s`}>
      <div css={css`font-size: 20px;padding-left: 20px`}>My Blog</div>
      <div css={css`display:flex; flex-grow:1; position: relative`}>
        <nav css={css`position: absolute; right: 0`}>
          <ul css={css`padding-right: 2vw; display: flex`}>
            {arr.map((it, i) => 
            <Link key={it.name} to={it.to}><li css={css`padding: 0 1vw; line-height: 50px; position: relative;
              &:after { content: ''; width: 100%; height: 4px; border-radius: 999px;background-color: #1890ff; position: absolute; top: 0; right: 50%; width: 0%; transition: 0.2s; ${it.to === l.pathname ? 'width:100%;transform: translateX(50%);':''}};
              &:hover {color: #1890ff}; &:hover:after {width: 100%; transform: translateX(50%)}; 
              ${it.to === l.pathname ? 'color: #1890ff;':''}
              @media (max-width: 450px) {font-size: 13px}`}>{it.name}</li></Link>)}
          </ul>
        </nav>
      </div>
    </header>
  )
}
export default Header

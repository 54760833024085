import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from "react-router-dom"
import { Provider } from 'react-redux'
import Parse from 'parse'
import './index.css'
import App from './pages/App'
import store from './pages/store'
import * as serviceWorker from './serviceWorker'


Parse.initialize('blog')
if (process.env.NODE_ENV === 'development') {
  Parse.serverURL = 'http://172.20.10.7:1337/parse'
} else {
  Parse.serverURL = window.location.protocol + '//hnh.me/parse'
}

;(async function () {
  if (!Parse.User.current()) {
    let res: any = await Parse.Cloud.run('userLogin')
    if (res.token) {
      await Parse.User.become(res.token)
    }
  }

  ReactDOM.render(<Router><Provider store={store}><App/></Provider></Router>, document.getElementById('root'))
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister()
}())


